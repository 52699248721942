<template>
<div class="login-background">
    <div class="h-10per">
        <img class="logo" src="@/assets/images/logo.png" />
    </div>
    <div class="wrap bg-white h-90per w-100per">
        <div class="mg-auto pt-20" style="width: 1250px;">
                <h2 class="mt-00 font-18 bold"><span>■</span> 협약센터 등록요청</h2>
            <div class="con_top">
                <input type="hidden" name="_token" id="_token" value="iu9xcVuFZ79uA3zQNaVTassFPxCio38exMLm1RxR">
                <input type="hidden" name="center_id" id="center_id" value="1">
                <input type="hidden" name="role_id" id="role_id" value="1"><!-- 상담사 -->
                <input type="hidden" name="callback" id="callback" value="">

                <div class="con">
                    <div class="con_table">
                        <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                            <tbody>
                                <tr>
                                    <th class="w-120px">아이디 <span class="red">*</span></th>
                                    <td class="left">
                                        <input type="text" v-model="id" @change="isValidID = false" class="ml-20 w-200px">
                                        <button type="button" class="default-btn ml-20" @click="checkID()">중복체크</button><br>
                                        <span class="ml-20"> 영문 대/소문자 + 숫자포함 6~20자</span>
                                    </td>
                                    <th>비밀번호 <span class="red">*</span> </th>
                                    <td class="left">
                                        <input type="text" v-model="pwd" class="ml-20 w-200px"><br>
                                        <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-120px">센터명 <span class="red">*</span></th>
                                    <td class="left" colspan="3">
                                        <input type="text" v-model="name" @change="isValidName = false" class="ml-20 w-200px">
                                        <button type="button" class="default-btn ml-20" @click="checkName()">중복체크</button>
                                    </td>
                                    <!-- <th>코드명</th>
                                <td class="left">
                                    <input type="text" class="ml-20 w-200px">
                                </td> -->
                                </tr>
                                <tr>
                                    <th>주소 <span class="red">*</span></th>
                                    <td class="left" colspan="3">
                                        <input type="text" v-model="post" disabled class="pl-20 ml-20 w-200px">
                                        <button type="button" class="default-btn ml-20 mb-10" @click="onModal()">주소 검색</button> <br>
                                        <input type="text" v-model="address" disabled class="pl-20 ml-20 mb-10 mr-10 w-400px">기본주소 <br>
                                        <input type="text" v-model="detailAddress" class="pl-20 ml-20 mr-10 w-400px">상세주소
                                    </td>
                                </tr>
                                <tr>
                                    <th>대표번호 <span class="red">*</span></th>
                                    <td class="left">
                                        <input type="text" class="ml-20" style="width:87px;" v-model="mainNumber1" id="mainNumber1" name="mainNumber1"> -
                                        <input type="text" style="width:100px;" v-model="mainNumber2" id="mainNumber2" name="mainNumber2" value=""> -
                                        <input type="text" style="width:100px;" v-model="mainNumber3" id="mainNumber3" name="mainNumber3" value="">
                                    </td>
                                    <th>센터장 <span class="red">*</span></th>
                                    <td class="left">
                                        <input type="text" v-model="centerCap" class="ml-20 w-200px">
                                    </td>
                                </tr>
                                <tr>
                                    <th>휴대폰번호 <span class="red">*</span></th>
                                    <td class="left">
                                        <select class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                            <option value="010">010</option>
                                            <option value="011">011</option>
                                            <option value="016">016</option>
                                            <option value="017">017</option>
                                            <option value="018">018</option>
                                            <option value="019">019</option>
                                        </select> -
                                        <input type="text" style="width:100px;" v-model="phone2" id="phone2" name="phone2" value=""> -
                                        <input type="text" style="width:100px;" v-model="phone3" id="phone3" name="phone3" value="">
                                    </td>
                                    <th>상담실 <span class="red">*</span></th>
                                    <td class="left">
                                        <input type="text" v-model="consRoom" class="ml-20 w-60px"> 개
                                    </td>
                                </tr>
                                <tr>
                                    <th>놀이치료실 <span class="red">*</span></th>
                                    <td class="left" colspan="3">
                                        <input type="text" v-model="playRoom" class="ml-20 w-60px"> 개
                                    </td>
                                </tr>
                                <tr>
                                    <th>상담서비스 <span class="red">*</span></th>
                                    <td class="left" colspan="3">
                                        <input type="checkbox" v-model="consService.adult.use" class="ml-20"> 성인
                                        <input type="checkbox" v-model="consService.child.use" class="ml-20"> 아동
                                        <input type="checkbox" v-model="consService.teen.use" class="ml-20"> 청소년
                                        <input type="checkbox" v-model="consService.family.use" class="ml-20"> 가족
                                        <input type="checkbox" v-model="consService.couple.use" class="ml-20"> 부부
                                        <input type="checkbox" v-model="consService.etc.use" class="ml-20"> 기타
                                    </td>
                                </tr>
                                <tr>
                                    <th>센터사진 <span class="red">*</span></th>
                                    <td class="left">
                                        <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.centerFile.click()">파일 선택</button>
                                        <input v-show="false" ref="centerFile" type="file" @change="uploadCenterPhoto($event)" /><br>
                                        <!-- <span v-if="centerFile.name" class="ml-20" style="cursor:pointer" @click="detailFile()">{{centerFile.name}}</span> -->
                                        <span v-for="(item, index) of centerFile" :key="index" class="ml-20" style="cursor:pointer" @click="detailFile()">{{item.name}}<br></span>
                                    </td>
                                    <th>사업자등록증 <span class="red">*</span></th>
                                    <td class="left">
                                        <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.registrationFile.click()">파일 선택</button>
                                        <input v-show="false" ref="registrationFile" type="file" @change="uploadFile($event, 'registrationFile')" /><br>
                                        <span v-if="registrationFile.name" class="ml-20" style="cursor:pointer" @click="detailFile()">{{registrationFile.name}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>통장사본 <span class="red">*</span></th>
                                    <td class="left" colspan="3">
                                        <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.bankbookFile.click()">파일 선택</button>
                                        <input v-show="false" ref="bankbookFile" type="file" @change="uploadFile($event, 'bankbookFile')" /><br>
                                        <span v-if="bankbookFile.name" class="ml-20" style="cursor:pointer" @click="detailFile()">{{bankbookFile.name}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>급여지급 <span class="red">*</span></th>
                                    <td class="left">
                                        <select class="ml-20" v-model="salaryType">
                                            <option value="개인">개인</option>
                                            <option value="계산서">계산서</option>
                                        </select> 
                                    </td>
                                    <th>계약금액 <span class="red">*</span></th>
                                    <td class="left">
                                        <input type="text" v-model="consPrice" class="ml-20 w-60px"> 원
                                    </td>
                                </tr>
                                <tr>
                                    <th>홈페이지 <span class="red">*</span></th>
                                    <td class="left" colspan="3">
                                        <input type="text" v-model="homepage" class="ml-20 w-300px">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="txt-red mt-10">* 등록요청 하신 후 관리자가 승인하면 CRM이용이 가능합니다.</p>
                        <div class="btns3 mgB80">
                            <a class="btn_sms mr-10 pointer" @click="moveLogin()">취소</a>
                            <a class="btn_cancle mr-10 pointer" @click="postCenterInfo()">등록</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <address-modal :isOnModal="isOnModal" @close="closeModal"></address-modal>
    </div>
    </div>
</template>

<script>
import {
    IDFormatCheck,
    PWFormatCheck,
    EmptyCheck,
} from '@/utils/validation.js'
import AddressModal from '@/components/modal/searchAddress.vue'
export default {
    components: {
        AddressModal: AddressModal,
    },
    data: () => ({
        isOnModal: false, // 주소 모달 on/off 여부

        post: '', // 주소코드

        id: '', // 아이디
        pwd: '', // 비밀번호
        name: '', // 센터명
        address: '', // 기본주소
        detailAddress: '', // 상세주소
        mainNumber1: '', // 대표번호
        mainNumber2: '', // 대표번호
        mainNumber3: '', // 대표번호
        centerCap: '', // 센터장
        phone1: '010',
        phone2: '',
        phone3: '',
        consRoom: 0, // 상담실 개수
        playRoom: 0, // 놀이치료실 개수
        consService: { // 상담서비스
            adult: {
                name: '성인',
                use: false
            },
            child: {
                name: '아동',
                use: false
            },
            teen: {
                name: '청소년',
                use: false
            },
            family: {
                name: '가족',
                use: false
            },
            couple: {
                name: '부부',
                use: false
            },
            etc: {
                name: '기타',
                use: false
            },
        },
        homepage: '', // 홈페이지
        centerFile: [], //  센터사진1 파일
        registrationFile: '', //  사업자등록증 파일
        bankbookFile: '', // 통장사본 파일
        permission: 'N', // 허가 여부
        isValidName: false, // 센터명 중복 여부 false 일때 중복체크 안된상태
        isValidID: false, // id 중복 여부 false 일때 중복체크 안된상태
        salaryType: '', // 급여지급
        consPrice: '' // 계약금액

    }),

    updated() {
        if (this.isOnModal) {
            this.$nextTick(function () {
                // 주소 찾기 결과 값 로드
                // this.$EventBus.$on('close', (x) => {
                //     this.addressVisible = x
                // })
                this.$EventBus.$on('closeSearchZipCode', data => {
                    if (data) {
                        this.address = data.address
                        this.post = data.zonecode
                        this.isOnModal = false

                        this.$EventBus.$off('close')
                        this.$EventBus.$off('closeSearchZipCode')
                    }
                })
            })
        }
    },

    methods: {

        // 센터 파일 업로드 (최대 3개까지)
        uploadCenterPhoto(event) {
            if (this.centerFile.length >= 3) {
                alert('센터사진은 최대 3개까지 업로드 가능합니다.')
                return false
            } else {
                // 포맷 검사
                var allowFormat = ['jpg', 'jpeg', 'png', 'gif', 'JPG', 'JPEG', 'PNG', 'GIF']
                var checked = false
                for (var item of allowFormat) {
                    if (event.target.files[0].name.includes(item)) {
                        checked = true
                        break
                    } else {
                        continue
                    }
                }
                if (checked) {
                    // 용량 검사
                    if (event.target.files[0].size > 1048576 * 2) {
                        alert('2MB 이하만 업로드 가능합니다.')
                        return false
                    }

                    this.centerFile.push(event.target.files[0])

                } else {
                    alert('파일 포맷은 jpg, jpeg, png, gif형식만 가능합니다.')
                    return false
                }

            }
        },

        // 센터명 중복체크 api
        checkID() {

            // 아이디 검사
            if (!EmptyCheck(this.id, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.id)) {
                    return false
                }
            }

            var params = {
                id: this.id
            }
            this.axios.get('/api/v1/user/dup', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        alert('사용 가능한 아이디입니다.')
                        this.isValidID = true
                    } else {
                        alert('이미 사용중인 아이디입니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })
        },

        // 센터명 중복체크 api
        checkName() {
            var params = {
                name: this.name
            }
            this.axios.get('/api/v1/center/dup', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        alert('사용 가능한 센터명입니다.')
                        this.isValidName = true
                    } else {
                        alert('같은 이름으로 등록된 센터가 있습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })
        },

        // 주소검색 모달 on
        onModal() {
            this.isOnModal = true
        },

        // 주소검색 모달 off
        closeModal() {
            this.isOnModal = false
        },

        // 프로필 사진 업로드
        uploadFile(event, fileType) {
            // 포맷 검사
            var allowFormat = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'PDF', 'JPG', 'JPEG', 'PNG', 'GIF']
            var checked = false
            for (var item of allowFormat) {
                if (event.target.files[0].name.includes(item)) {
                    checked = true
                    break
                } else {
                    continue
                }
            }
            if (checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576 * 2) {
                    alert('2MB 이하만 업로드 가능합니다.')
                    this.$refs[fileType].value = ''
                    return false
                }

                this[fileType] = event.target.files[0]

                this.$refs[fileType].value = ''
            } else {
                alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                this.$refs[fileType].value = ''
                return false
            }
        },

        // 등록
        postCenterInfo() {
            
            // 아이디 검사
            if (!EmptyCheck(this.id, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.id)) {
                    return false
                }
            }

            // 아이디 중복체크 했는지 여부
            if (!this.isValidID) {
                alert('아이디 중복체크를 해주세요.')
                return false
            }
            
            // 비밀번호 검사
            if (!EmptyCheck(this.pwd, '암호를')) {
                return false
            } else {
                if (!PWFormatCheck(this.pwd)) {
                    return false
                }
            }
            
            if(!EmptyCheck(this.name, '센터명을')) {
                return false
            }

            // 센터명 중복체크 했는지 여부
            if (!this.isValidName) {
                alert('센터명 중복체크를 해주세요.')
                return false
            }

            if (!EmptyCheck(this.post, '주소를')) {
                return false
            }

            // 대표 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.mainNumber1 !== '' && this.mainNumber2 !== '' && this.mainNumber3 !== '') {
                var mainNumber = this.mainNumber1 + '-' + this.mainNumber2 + '-' + this.mainNumber3
            } else {
                var mainNumber = ''
            }

            if (mainNumber === '') {
                alert('대표번호를 입력해주세요')
                return false
            }

            if (!EmptyCheck(this.centerCap, '센터장을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            if (phone === '') {
                alert('핸드폰정보를 입력해주세요')
                return false
            }

            let useCount = 0
            
            for (var key of Object.keys(this.consService)) {
                if (!this.consService[key].use) {
                       useCount++
                }
            }

            if (useCount === 6) {
                alert('상담서비스를 선택해주세요')
                return false
            }

            if (this.centerFile.length === 0) {
                alert('센터사진 파일을 첨부하세요')
                return false
            }

            if (this.registrationFile.length === 0) {
                alert('사업자등록증 파일을 첨부하세요')
                return false
            }

            if (this.bankbookFile.length === 0) {
                alert('통장사본 파일을 첨부하세요')
                return false
            }  
            
            if (this.salaryType === '') {
                alert('급여지급 정보를 입력해주세요')
                return false
            }

            if (this.consPrice === '') {
                alert('계약금액을 입력해주세요')
                return false
            }
            const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            if (korean.test(this.consPrice)) {
                alert('계약금액에 한글이 포함될수없습니다.')
                return false
            }            

            var params = new FormData()
            params.append('id', this.id)
            params.append('pwd', this.pwd)
            params.append('name', this.name)
            params.append('post', this.post)
            params.append('address', this.address)
            params.append('detailAddress', this.detailAddress)
            params.append('mainNumber', mainNumber)
            params.append('centerCap', this.centerCap)
            params.append('phone', phone)
            params.append('consRoom', this.consRoom)
            params.append('playRoom', this.playRoom)
            params.append('homepage', this.homepage)
            params.append('permission', this.permission)
            params.append('salaryType', this.salaryType)
            
            params.append('consPrice', this.consPrice)
            params.append('idx', -1)

            // 상담서비스 checkbox => string 매핑작업
            var consService = ''
            for (var key of Object.keys(this.consService)) {
                if (this.consService[key].use) {
                    if (consService === '') {
                        consService += this.consService[key].name
                    } else {
                        consService += '/' + this.consService[key].name
                    }

                }
            }

            params.append('consService', consService)

            // 각 파일 빈 binary로 추가해놓기
            params.append('centerFile1', new Blob())
            params.append('centerFile2', new Blob())
            params.append('centerFile3', new Blob())
            params.append('regFile', new Blob())
            params.append('bankFile', new Blob())

            for (var [index, file] of this.centerFile.entries()) {
                params.delete('centerFile' + (index + 1))
                params.append('centerFile' + (index + 1), file)
            }

            if (this.registrationFile !== '') {
                params.delete('regFile')
                params.append('regFile', this.registrationFile)
            }
            if (this.bankbookFile !== '') {
                params.delete('bankFile')
                params.append('bankFile', this.bankbookFile)
            }

            this.axios.post('/api/v1/center/', params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    alert('등록되었습니다.')
                    this.$router.push('/login')
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                
        },

        // 이전 페이지로 이동
        moveLogin() {
            this.$router.push('/login')
        }
    }
}
</script>
